// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-body {
  width: 100%;
  min-height: 220px;
  display: grid;
  grid-template-rows: 1fr auto;
}

.content {
  min-height: 121px;
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-rows: 2fr;
  align-items: center;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.38px;
}

.title {
  margin-top: 10px;
  width: 100%;
  padding: 0px 24px;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
}

.text {
  width: 100%;
  padding: 0px 24px;
  font-size: 18px;
  text-align: center;
}

.actions {
  width: 100%;
  height: 64px;
  border-radius: 12px;
  padding: 8px 15px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: auto;
  -moz-column-gap: 8px;
       column-gap: 8px;
  background-color: black;
  border-radius: 12px;
}

div button {
  width: 129px;
  height: 48px;
  border-radius: 24px;
}

.content-with-formatted-text .title {
  padding-top: 35px;
}
.content-with-formatted-text .title p {
  margin: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/dialog-confirmation/dialog-confirmation.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,aAAA;EACA,4BAAA;AACF;;AAEA;EACE,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,iBAAA;EACA,uBAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,uBAAA;EACA,oBAAA;OAAA,eAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAGE;EACE,iBAAA;AAAJ;AACI;EACE,WAAA;AACN","sourcesContent":[".dialog-body {\n  width: 100%;\n  min-height: 220px;\n  display: grid;\n  grid-template-rows: 1fr auto;\n}\n\n.content {\n  min-height: 121px;\n  display: grid;\n  grid-template-rows: 1fr;\n  grid-auto-rows: 2fr;\n  align-items: center;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.33;\n  letter-spacing: -0.38px;\n}\n\n.title {\n  margin-top: 10px;\n  width: 100%;\n  padding: 0px 24px;\n  font-size: 24px;\n  font-weight: 900;\n  text-align: center;\n}\n\n.text {\n  width: 100%;\n  padding: 0px 24px;\n  font-size: 18px;\n  text-align: center;\n}\n\n.actions {\n  width: 100%;\n  height: 64px;\n  border-radius: 12px;\n  padding: 8px 15px;\n  display: grid;\n  grid-auto-flow: column;\n  grid-template-columns: 1fr 1fr;\n  grid-auto-columns: auto;\n  column-gap: 8px;\n  background-color: black;\n  border-radius: 12px;\n}\n\ndiv button {\n  width: 129px;\n  height: 48px;\n  border-radius: 24px;\n}\n\n.content-with-formatted-text {\n  .title {\n    padding-top: 35px;\n    p {\n      margin: 0px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
