import { KeycloakEventType, KeycloakService } from 'keycloak-angular';

import Auth from '@aws-amplify/auth';

import { environment } from 'src/environments/environment';

import { ConnectivityService } from '../services/connectivity.service';
import { AuthService } from './auth.service';

let locationHash: string;

async function handleStateRedirect() {
  locationHash ??= window.location.hash;
  const params = new URLSearchParams(locationHash.substring(1));
  const stateParam = params.get('state');

  const state = (function () {
    try {
      new URL(stateParam);
      return stateParam;
    } catch (_) {
      return '';
    }
  })();

  if (state) {
    window.location.href = state;

    // Return a Promise that never resolves to prevent the site from loading
    // while the user is being redirected.
    return new Promise(() => {});
  }

  return Promise.resolve();
}

async function handleUserAuthentication(
  keycloak: KeycloakService,
  authService: AuthService
) {
  const idToken = keycloak.getKeycloakInstance().idToken;
  const accessToken = keycloak.getKeycloakInstance().token;
  const refreshToken = keycloak.getKeycloakInstance().refreshToken;

  if (idToken && accessToken && refreshToken) {
    // Do not try to federate the user again as it could create unexpected
    // issues, so if they're already authenticated, we only set the cookies.
    try {
      await Auth.currentAuthenticatedUser();

      authService.setAmplifyV5PlusCookies({
        idToken,
        accessToken,
        refreshToken,
      });
    } catch (error) {
      await authService.authorize();
    }

    await handleStateRedirect();
  }
}

export function initializeKeycloak(
  connectivityService: ConnectivityService,
  keycloak: KeycloakService,
  authService: AuthService
) {
  if (!connectivityService.isOnline) {
    return () => true;
  }

  locationHash = window.location.hash;

  return () =>
    keycloak
      .init({
        config: {
          url: environment.keycloak.issuer,
          realm: environment.keycloak.realm,
          clientId: environment.keycloak.clientId,
        },
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri:
            window.location.origin + '/assets/silent-check-sso.html',
        },
        loadUserProfileAtStartUp: false,
        enableBearerInterceptor: false,
      })
      .then(async () => {
        await handleUserAuthentication(keycloak, authService);

        keycloak.keycloakEvents$.subscribe({
          async next(event) {
            if (event.type == KeycloakEventType.OnTokenExpired) {
              await keycloak.updateToken(-1);
              await handleUserAuthentication(keycloak, authService);
            } else if (
              event.type == KeycloakEventType.OnAuthRefreshError ||
              event.type == KeycloakEventType.OnAuthLogout
            ) {
              window.location.assign('/');
            }
          },
        });
      });
}
