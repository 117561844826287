export enum DialogType {
  default,
  textWithTitle,
  withWarning,
  textList,
}

export interface DialogData {
  title: string;
  text?: string;
  formattedText?: string;
  type?: DialogType;
  action: string;
  icon: string;
}
