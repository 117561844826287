// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cognito-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
  gap: 5.5rem;
}
.cognito-component .logo-image {
  width: 400px;
}
.cognito-component .message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.cognito-component .message-container .login-message {
  font-weight: 400;
  font-size: 2rem;
  text-align: center;
}
.cognito-component .message-container .message-image {
  width: 60px;
}
.cognito-component .message-container .support-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}
.cognito-component .message-container .support-message {
  font-weight: 400;
  font-size: 1.5rem;
}
.cognito-component .message-container a {
  text-decoration: underline;
  color: #008751;
}
.cognito-component .message-container .spin {
  animation: spin 10s linear infinite;
  transform-origin: center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/core/auth/cognito-callback/cognito-callback.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,WAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AAAJ;AAEI;EACE,gBAAA;EACA,eAAA;EACA,kBAAA;AAAN;AAGI;EACE,WAAA;AADN;AAII;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;AAFN;AAKI;EACE,gBAAA;EACA,iBAAA;AAHN;AAMI;EACE,0BAAA;EACA,cAAA;AAJN;AAOI;EACE,mCAAA;EACA,wBAAA;AALN;;AAUA;EACE;IACE,uBAAA;EAPF;EASA;IACE,yBAAA;EAPF;AACF","sourcesContent":[".cognito-component {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-top: 6rem;\n  gap: 5.5rem;\n\n  .logo-image {\n    width: 400px;\n  }\n\n  .message-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 2rem;\n\n    .login-message {\n      font-weight: 400;\n      font-size: 2rem;\n      text-align: center;\n    }\n\n    .message-image {\n      width: 60px;\n    }\n\n    .support-container {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      gap: 1.5rem;\n    }\n\n    .support-message {\n      font-weight: 400;\n      font-size: 1.5rem;\n    }\n\n    a {\n      text-decoration: underline;\n      color: #008751;\n    }\n\n    .spin {\n      animation: spin 10s linear infinite;\n      transform-origin: center;\n    }\n  }\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
