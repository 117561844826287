import { Component } from '@angular/core';

import { environment } from 'src/environments/environment';

import { AuthService } from '../core/auth/auth.service';

/**
 * This component is is supposed to be embedded in an iframe on OnTrax
 * subsystems so the Keycloak APP_INITIALIZER token refresh mechanism
 * keeps executing in the background and other auth tasks can be executed.
 */
@Component({
  selector: 'app-iframe-auth-handler',
  standalone: true,
  template: '<p>Iframe Auth Handler Works!</p>',
})
export class IframeAuthHandlerComponent {
  constructor(private authService: AuthService) {
    window.addEventListener('message', (event) => {
      if (!environment.allowedIframeOrigins.includes(event.origin)) return;

      if (event.data.action === 'logout') {
        this.authService.logout().then(() => {
          window.parent.postMessage({ status: 'logged_out' }, event.origin);
        });
      }
    });
  }
}
